/*NAME CARD STYLING*/
/*NAME CARD STYLING*/
/*NAME CARD STYLING*/

.SpeakIcn {
  cursor: pointer;
  width: 2.5vh;
  height: 2.5vh;
  background: url("../../images/whiteOn.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin-left: 1.5vh;
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.SpeakStop {
  cursor: pointer;
  width: 2.5vh;
  height: 2.5vh;
  background: url("../../images/whiteOff.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin-left: 1.5vh;
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.nameCard {
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-top: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-radius: 1vh;
  display: flex;
  opacity: 1;
  flex-direction: column;
  overflow: hidden;
  transition: 1s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.loadCard {
  width: 100%;
  height: 100%;
  border-radius: 1vh;
  display: flex;
  opacity: 1;
  flex-direction: column;
  overflow: hidden;
  transition: 1s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.nameDes {
  width: 100.5%;
  flex: 1;
  display: flex;
  align-items: center;
}

.nameWishList {
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nameCardTitle {
  width: 100%;
  height: 6vh;
  display: flex;
  align-items: center;
}

.nameCardTitle > p {
  font-size: 3vh;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Roboto";
  font-weight: 100;
  line-height: 6vh;
  padding-left: 2vh;
  max-width: 80%;
}

.nameGender {
  width: 100%;
  height: 4vh;
  display: flex;
  align-items: center;
}

.nameGender > p {
  font-size: 2vh;
  color: rgba(255, 255, 255, 0.8);
  font-family: "Roboto";
  font-weight: 100;
  line-height: 4vh;
  padding-left: 2vh;
  max-width: 80%;
}

/* .nameGender > p:nth-child(2) {
  margin-left: 1vh;
} */

.nameGender > p span {
  font-weight: 400;
}

.TpSpeakIcn {
  cursor: pointer;
  width: 2.5vh;
  height: 2.5vh;
  background: url("../../images/whiteOn.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin-left: 1.5vh;
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.TpSpeakStop {
  cursor: pointer;
  width: 2.5vh;
  height: 2.5vh;
  background: url("../../images/whiteOff.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin-left: 1.5vh;
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.TpSpeakIcn:hover,
.TpSpeakStop:hover {
  opacity: 0.7;
}

.nameDes p {
  word-wrap: break-word;
  width: 85%;
  height: 110%;
  margin-left: 2vh;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto";
  font-weight: 100;
  font-size: 1.8vh;
  line-height: 2.5vh;
  font-style: italic;
}

.wishlstBTn {
  width: 3vh;
  height: 3vh;
  background-image: linear-gradient(135deg, #f97794 10%, #854de0 100%);
  overflow: hidden;
  border-radius: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2vh;
  cursor: pointer;
  z-index: 99;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.wishlstBTn:hover {
  background-image: linear-gradient(135deg, #f97794 0%, #854de0 90%);
}

.wishlstBTn p {
  font-size: 2.5vh;
  font-family: "Roboto";
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.wishlstBTn:hover p {
  color: rgba(255, 255, 255, 0.9);
}

/*NAME CARD STYLING*/
/*NAME CARD STYLING*/
/*NAME CARD STYLING*/
