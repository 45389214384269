.allNamesPage {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: row;
}
.allnamesLeft {
  width: 5vw;
  height: 98%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.anlTop {
  width: 10vh;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BacktoHomeButton {
  width: 5vh;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2.5vh;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.5s;
  cursor: pointer;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.BacktoHomeButton:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.backIcn {
  width: 2vh;
  height: 2vh;
  background: url("../../images/back.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.BacktoHomeButton:hover .backIcn {
  opacity: 1;
}

.anlDown {
  width: 10vh;
  flex: 1;
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alphabets {
  width: 5vh;
  height: 85vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2.5vh;
}

.alphabet {
  width: 3vh;
  height: 3vh;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.alphabet p {
  position: absolute;
  cursor: pointer;
  font-size: 1.8vh;
  font-family: "Roboto";
  font-weight: 100;
  color: rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.alphabet:hover p {
  font-size: 3.2vh;
  color: rgba(255, 255, 0, 0.9);
}

.alphabet:hover + .alphabet p {
  font-size: 2vh;
}

.allNamesRight {
  flex: 1;
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.anrContainer {
  width: 95%;
  height: 95%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 2vh;
}
.track-vertical {
  width: 0.5vw;
  top: 2.5%;
  height: 95%;
  right: 1vh;
  border-radius: 0.5vw;
  overflow: hidden;
}

.thumb-vertical {
  width: 0.5vw;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 0.5vw;
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.thumb-vertical:hover {
  background-color: rgba(0, 0, 0, 0.5);
}
.containNames {
  width: 100%;
  height: auto;
}

.simplebarAllNames {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding: 0;
}

.NamesGrid {
  margin-left: 2.5%;
  width: 95%;
  height: auto;
  padding: 5vh 0;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.NamesGrid > p {
  font-size: 3vh;
  height: 5vh;
  line-height: 5vh;
  padding-bottom: 2vh;
  padding-left: 2vh;
  color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin-bottom: 3vh;
  font-family: "Roboto";
  font-weight: 600;
}

.NamesWithContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.allNamesNameCard {
  width: 17vw;
  height: 12vw;
  margin: 2vw;
}

/*.allNamesPage div {
  border: 1px solid white;
}*/
