.WishList {
  width: 100%;
  opacity: 0;
  height: 130vh;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.WishList.mob {
  width: 100%;
  height: 100%;
}

.WishList.start {
  opacity: 1;
  height: 100vh;
}

.WishList.mob.start {
  opacity: 1;
  height: 100%;
  overflow: hidden;
}
.wishlistContainer {
  width: 90%;
  height: 95vh;
  display: flex;
  flex-direction: column;
  border-radius: 2vh;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.wishlistContainer.mob {
  width: 90%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 2vh;
  background-color: rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.wlcTop {
  width: 100%;
  height: 6vh;
  display: flex;
  align-items: center;
}
.wlcBackBTn {
  width: 4vh;
  height: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1vh;
  border-radius: 2vh;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.wlcBackInc {
  width: 2vh;
  height: 2vh;
  background: url("../../images/back.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.wlcBackBTn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.wlcBackBTn:hover .wlcBackInc {
  transform: translateX(3px);
}

.wlcCenter {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
}
.wlcCenterContainer {
  width: 100%;
  height: 95%;
}

.WishListNameContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.WishListNameCard {
  width: 18vw;
  height: 10vw;
  margin: 2.25vw;
}
.WishListNameCard.mob {
  width: 90%;
  height: 22vh;
  margin: 5%;
}

.wlcbottom {
  width: 100%;
  flex-basis: 7vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.sortByBtn {
  padding: 0 2vh;
  height: 3.5vh;
  border-radius: 3.5vh;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2vh;
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sortByBtn:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.sortByBtn p {
  font-size: 1.8vh;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto";
  font-weight: 100;
}

/*.wishlistContainer > div {
  border: 1px solid white;
}*/
