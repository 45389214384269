.m-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.m-container.chrome {
  width: 100%;
  height: 95vh;
  position: absolute;
  bottom: 0;
}

.m-indeSlider {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.m-indeSlider.chrome {
  position: absolute;
  top: 2vh;
  left: 0;
  width: 100%;
  height: 95vh;
  overflow: hidden;
}

.m-isContainer {
  width: 300vw;
  height: 100vh;
  margin-left: -100vw;
  display: flex;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.m-isContainer.left {
  margin-left: 0;
}
.m-isContainer.right {
  margin-left: -200vw;
}

.m-isPage {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
