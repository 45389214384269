.logInPage {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.homeBtn {
  width: 8vw;
  height: 6vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  display: flex;
  top: 5vh;
  left: 5vh;
  border-radius: 3vh;
  overflow: hidden;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
}

.homeBtn:hover {
  width: 9vw;
  background-color: rgba(0, 0, 0, 0.5);
}

.logInSec {
  width: 30%;
  height: 60vh;
  border-radius: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

/*LOGIN SEC*/
/*LOGIN SEC*/
/*LOGIN SEC*/
/*LOGIN SEC*/
/*LOGIN SEC*/

.lipBackBtn {
  width: 6vh;
  height: 6vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lipBackInc {
  width: 4vh;
  height: 4vh;
  background: url("../../images/next.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  transform: rotate(180deg);
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.lipBackLabel {
  flex: 1;
}

.lipBackLabel p {
  font-size: 3vh;
  line-height: 6vh;
  font-family: "Roboto";
  font-weight: 600;
  color: rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  margin-left: 0;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  text-decoration: none;
}

.homeBtn:hover p {
  margin-left: 1vw;
}

.homeBtn:hover .lipBackInc {
  transform: rotate(0deg);
}

.lpuserForm {
  width: 80%;
  height: 30vh;
  border-radius: 1vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
}

.lpFormLabel {
  font-size: 2vh;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 3vh;
}

.lpUseranemSec,
.lpPasswordSec {
  width: 70%;
  height: 5vh;
  margin-top: 1vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.lpUseranemSec input,
.lpPasswordSec input {
  height: 3vh;
  line-height: 3vh;
  border: none;
  border-bottom: 0.1vh solid rgba(0, 0, 0, 0.1);
  background-color: transparent;
  font-size: 1.5vh;
  box-shadow: none;
  color: rgba(0, 0, 0, 0.5);
}

.lpUseranemSec input {
  padding: 0 1vh;
}
.lpPasswordSec input {
  padding: 0 4vh 0 1vh;
}

.lpVisibleBtn {
  width: 3vh;
  height: 2vh;
  position: absolute;
  align-self: flex-end;
  margin-top: 0.5vh;
  cursor: pointer;
}

.lpVisibleIcn {
  width: 2vh;
  height: 2vh;
  opacity: 0.5;
}

.lpUseranemSec span,
.lpPasswordSec span {
  font-size: 1.15vh;
  line-height: 2vh;
  color: rgba(255, 45, 45, 0.5);
  text-align: end;
}

.lpLogInBtn {
  width: 30%;
  height: 3.5vh;
  margin-top: 3vh;

  background: linear-gradient(135deg, #12c2e9, #c471ed);
  filter: hue-rotate(-30deg);
  border-radius: 0.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.lpLogInBtn:hover {
  filter: hue-rotate(0deg);
}

.lpLogInBtn p {
  font-size: 1.5vh;
  color: rgba(255, 255, 255, 0.9);
}

.lplogInButtons {
  width: 80%;
  height: 5vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
  border-radius: 1vh;
  cursor: pointer;
  background-color: whitesmoke;
}

.lpcaIcon {
  width: 2.5vh;
  height: 2.5vh;
}

.lplogInButtons p {
  margin-left: 1vh;
  font-size: 1.8vh;
  color: rgba(0, 0, 0, 0.6);
}

.fbBtn {
  background-color: #4e74c4;
}

.fbBtn p {
  color: rgba(255, 255, 255, 0.9);
}

.lpcaIcon.xy {
  background: url("../../images/icon.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.lpcaIcon.google {
  background: url("../../images/google.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.lpcaIcon.fb {
  background: url("../../images/fb_icon.png");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
