.FullScreenMenu {
  width: 100%;
  height: 100vh;
  position: absolute;
  z-index: 9999;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.95);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.FullScreenMenu.close {
  background-color: rgba(0, 0, 0, 0);
}

.FullScreenMenuContainer {
  margin-left: 62.5vw;
  width: 25vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.FullScreenMenuContainer.close {
  background-color: rgba(0, 0, 0, 0);
}

.menuContainer {
  margin-left: -25%;
  width: 50%;
  height: 80%;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  opacity: 1;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.menuContainer.close {
  opacity: 0;
  margin-left: 0%;
}

.closeBtn {
  width: 100%;
  height: 7vh;
  display: flex;
  justify-content: flex-end;
}

.CloseBtnLines {
  width: 7vh;
  height: 7vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
}

.CloseBtnLine {
  position: absolute;
  width: 100%;
  height: 0.5vh;
  background-color: rgba(255, 255, 255, 0.9);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.CloseBtnLines:hover .CloseBtnLine {
  background-color: rgba(255, 0, 100, 0.9);
}

.CloseBtnLine:nth-child(1) {
  top: 3.25vh;
}
.CloseBtnLine:nth-child(2) {
  bottom: 3.25vh;
}

.CloseBtnLine.open:nth-child(1) {
  top: 3.25vh;
  transform: rotate(45deg);
}
.CloseBtnLine.open:nth-child(2) {
  bottom: 3.25vh;
  transform: rotate(-45deg);
}

.menuList {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 5vh;
}

.menuListItems {
  width: 100%;
  height: 3vh;
  margin: 1vh 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.menuList p {
  flex: 1;
  text-align: right;
  font-size: 3vh;
  line-height: 3vh;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Roboto";
  font-weight: 900;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.menuItemHoverLine {
  width: 0;
  height: 1vh;
  background: linear-gradient(135deg, #12c2e9, #c471ed, #f64f59);
  margin-left: 1vh;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.menuListItems:hover > .menuItemHoverLine {
  width: 15%;
}

.menuListItems:hover p {
  color: rgba(255, 255, 255, 1);
}

.upgrade:hover p {
  color: rgba(255, 208, 0, 1);
}

.socialLinks {
  width: 100%;
  height: 4vh;
  display: flex;
  justify-content: space-between;
}

.socialLinks > div {
  width: 4vh;
  height: 4vh;
  cursor: pointer;
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.socialLinks > div:hover {
  opacity: 1;
}

.fb {
  background: url("../../images/fb.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.Insta {
  background: url("../../images/insta.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.Tweet {
  background: url("../../images/tweet.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.Git {
  background: url("../../images/git.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
}

/*.FullScreenMenu div {
  border: 1px solid white;
}*/
