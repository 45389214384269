.m-allnames {
  width: 100%;
  height: 81vh;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;

  align-items: center;
}

.m-anContainer {
  width: 90%;
  height: 70vh;
  display: flex;
  justify-content: space-between;
  border-radius: 2vh;
  background-color: rgba(0, 0, 0, 0.3);
  overflow: hidden;
}

.m-alNames {
  flex: 1;
  height: 100%;
  border-right: 0.1vw solid rgba(255, 255, 255, 0.9);
}

.m-alphabets {
  width: 7vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.m-alphabets > p {
  height: 3.5%;
  line-height: 100%;
  font-size: 1.8vh;
  color: rgba(255, 255, 255, 1);
  font-family: "Roboto";
  font-weight: 100;
  cursor: pointer;
}
.m-alphabets > p:hover {
  color: rgba(255, 255, 0, 1);
}

.m-allNamesNameCard {
  width: 90%;
  height: 22vh;
  margin: 5%;
}
