.Load {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes blink {
  0% {
    background-color: rgba(1, 1, 1, 0.05);
  }
  50% {
    background-color: rgba(1, 1, 1, 0.1);
  }
  100% {
    background-color: rgba(1, 1, 1, 0.05);
  }
}
.squareLoad {
  width: 80%;
  height: 80%;
  border-radius: 1vh;
  background-color: rgba(1, 1, 1, 0.1);
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-duration: 1.5s;
}
.roundLoad {
  width: 70%;
  height: 70%;

  border-radius: 50%;
  background-color: rgba(1, 1, 1, 0.1);
  animation-name: blink;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-duration: 1.5s;
}
