.Homepage {
  width: 100%;
  height: 115vh;
  display: flex;
  flex-direction: column;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.Homepage.start {
  height: 100vh;
}

.hpItems {
  display: flex;
  margin-top: 5vh;
  opacity: 0;
}
s_active {
  color: teal;
}

/*DIVS*/
/*DIVS*/
/*DIVS*/
/*DIVS*/
/*DIVS*/
.hpItems:nth-child(1) {
  width: 100%;
  height: 40vh;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hpItems:nth-child(2) {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: center;
  z-index: 9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hpItems:nth-child(3) {
  width: 100%;
  flex-basis: 40vh;
  z-index: 1;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hpItems:nth-child(1).searchShow {
  height: 30vh;
}

.SearchResultsHome {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hpItemsStart {
  margin-top: 0;
  opacity: 1;
}

/*DIVS*/
/*DIVS*/
/*DIVS*/
/*DIVS*/
/*DIVS*/

.hpSearchSec {
  width: 80%;
  height: 15vh;
  display: flex;
  flex-direction: column;
}

.searchLabel {
  width: 100%;
  flex-basis: 1vh;
}

.searchLabel p {
  position: absolute;
  font-size: 3vh;
  line-height: 1vh;
  font-family: "Roboto";
  font-weight: 900;
  color: rgba(255, 255, 255, 0.9);
  margin-left: 1vh;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.searchLabel.focusOut p {
  font-size: 5vh;
  font-family: "Roboto";
  font-weight: 900;
  margin-top: 1vh;
  line-height: 14vh;
  color: rgba(255, 255, 255, 0.7);
  padding-left: 1vh;
  z-index: -1;
}

.HpSearchBar {
  width: 100%;
  flex: 1;
  /*border: 0.5vh solid rgba(255, 255, 255, 0.7);*/
  overflow: hidden;
  display: flex;
}

.HpSearchwhiteLine {
  width: 100%;
  height: 1vh;
  border-radius: 1vh;
  background-color: rgba(255, 255, 255, 0.7);
}

.hpsearchIcn {
  width: 4vh;
  height: 4vh;
  margin-left: 2vh;
  margin-bottom: 3.5vh;
  opacity: 0.8;
  align-self: flex-end;
  background: url("../../images/search.svg");
  background-size: 100% auto;
  background-repeat: no-repeat;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hpsearchIcn:hover {
  opacity: 0.9;
}

.hpsearchIcnClose {
  cursor: pointer;
  background: url("../../images/close.svg");
  width: 4vh;
  height: 4vh;
  margin-left: 2vh;
  margin-bottom: 3.5vh;
  opacity: 0.8;
  align-self: flex-end;
  background-size: 100% auto;
  background-repeat: no-repeat;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hpsearchIcnClose:hover {
  opacity: 0.9;
}

.HpSearchBar input {
  margin-left: 2vh;
  width: 88%;
  height: 100%;
  border: none;
  background: transparent;
  font-size: 5vh;
  line-height: 14vh;
  color: rgba(255, 255, 255, 0.8);
  z-index: 9;
  font-family: "Roboto";
  font-weight: 900;
}

/*RANDOM BTNS*/
/*RANDOM BTNS*/
/*RANDOM BTNS*/
/*RANDOM BTNS*/

.hpRandomBtns {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.randomBtn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gender {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.religion {
  flex: 1.5;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.genderBtn {
  cursor: pointer;
  width: 25%;
  height: 4.5vh;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-radius: 1vh;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.genderBtn:nth-child(2) {
  margin-left: 5%;
}

.genderBtn p {
  text-align: center;
  line-height: 4.5vh;
  font-size: 2vh;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto";
  font-weight: 100;
}

.genderBtn:hover {
  background: rgba(255, 255, 255, 0.2);
}
.genderBtn:hover p {
  color: rgba(255, 255, 255, 0.9);
}

.genderBtn.selected {
  background: rgba(14, 255, 14, 0.1);
  border-bottom: 0.3vh solid rgba(14, 255, 14, 0.9);
}

.genderBtn.selected:hover {
  background: rgba(14, 255, 14, 0.2);
  border-bottom: 0.3vh solid rgba(14, 255, 14, 0.9);
}

/*RELIGION SEC*/
/*RELIGION SEC*/
/*RELIGION SEC*/

.religionTab {
  width: 80%;
  height: 4.5vh;
  position: relative;
}

.selectionReligion {
  width: 100%;
  height: 4.5vh;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-radius: 1vh;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.selectionReligion:hover {
  background: rgba(255, 255, 255, 0.2);
}

.selectionReligion p {
  width: 100%;
  line-height: 4.5vh;
  font-size: 2vh;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto";
  font-weight: 100;
  padding-left: 2vh;
}

.dropdownIcn {
  width: 2vh;
  height: 2vh;
  margin-top: -1vh;
  right: 2vh;
  position: absolute;
  background: url("../../images/down.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  align-self: flex-end;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  opacity: 0.7;
}

.dropdownIcn.select {
  transform: rotate(180deg);
}

/*DROPDOWN*/
/*DROPDOWN*/
/*DROPDOWN*/
/*DROPDOWN*/
.religionDropDown {
  position: absolute;
  width: 100%;
  height: 20vh;
  margin-top: 1vh;
  background: rgba(255, 255, 255, 0.5);
  border-bottom: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-top: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-radius: 1vh;
}

.rgDropDownContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
  margin-bottom: 1vh;
  align-items: center;
}

.DDItem {
  width: 80%;
  height: 5vh;
  cursor: pointer;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.DDItem p {
  font-size: 1.8vh;
  padding-left: 2vh;
  line-height: 5vh;
  color: rgba(0, 0, 0, 0.7);
  font-family: "Roboto";
  font-weight: 400;
}
/*DROPDOWN*/
/*DROPDOWN*/
/*DROPDOWN*/

/*RELIGION SEC*/
/*RELIGION SEC*/
/*RELIGION SEC*/
/*RELIGION SEC*/

/*BUTTON*/
/*BUTTON*/
/*BUTTON*/

.randomBtn p {
  font-size: 3vh;
  text-align: center;
  line-height: 10vh;
  width: 70%;
  height: 10vh;
  color: rgba(255, 255, 255, 0.8);
  background-image: linear-gradient(135deg, #f97794 10%, #854de0 100%);
  overflow: hidden;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5vh;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 100;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.randomBtn p:hover {
  background-image: linear-gradient(135deg, #f97794 0%, #854de0 90%);
  box-shadow: 6px 8px 5px 0px rgba(0, 0, 0, 0.75);
}

/*BUTTON*/
/*BUTTON*/
/*BUTTON*/

/*RANDOM BTNS*/
/*RANDOM BTNS*/
/*RANDOM BTNS*/

.TodaysPick {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TodaysPick > p {
  font-family: "Roboto";
  font-weight: 900;
  font-size: 14.1vw;
  line-height: 14vw;
  position: absolute;
  bottom: 0;
  color: rgba(255, 255, 255, 0.05);
  z-index: -1;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.TodaysPick > p.fade {
  color: rgba(255, 255, 255, 0);
}

/*CARD*/
/*CARD*/
/*CARD*/
.hpItems {
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.hpItems:nth-child(3).opacity {
  opacity: 0.3;
}

.tpCardContainer {
  width: 80%;
  height: 20vh;
}
.tpCard {
  width: 20vw;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-top: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-radius: 1vh;
  display: flex;
  opacity: 1;
  flex-direction: column;
  overflow: hidden;
  transition: 1s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.tpCardLoad {
  width: 20vw;
  height: 100%;
  border-radius: 1vh;
  display: flex;
  opacity: 1;
  flex-direction: column;
  overflow: hidden;
  transition: 1s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.tpCard.slide {
  margin-left: -20%;
  opacity: 0;
}

.tpDes {
  width: 100%;
  flex: 1;
  display: flex;
  overflow: hidden;
}

.tpWishList {
  width: 100%;
  height: 5vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.tpName {
  width: 100%;
  height: 6vh;
  display: flex;
  align-items: center;
}

.tpName > p {
  font-size: 3vh;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Roboto";
  font-weight: 100;
  line-height: 6vh;
  padding-left: 2vh;
  max-width: 80%;
}

.TpSpeakIcn {
  cursor: pointer;
  width: 2.5vh;
  height: 2.5vh;
  background: url("../../images/whiteOn.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin-left: 1.5vh;
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.TpSpeakStop {
  cursor: pointer;
  width: 2.5vh;
  height: 2.5vh;
  background: url("../../images/whiteOff.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  margin-left: 1.5vh;
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.TpSpeakIcn:hover,
.TpSpeakStop:hover {
  opacity: 0.7;
}

.tpDes > p {
  width: 100%;
  height: 90%;
  padding-left: 2vh;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto";
  font-weight: 100;
  font-size: 1.8vh;
  line-height: 2.5vh;
  font-style: italic;
}

.wishlstBTn {
  width: 3vh;
  height: 3vh;
  background-image: linear-gradient(135deg, #f97794 10%, #854de0 100%);
  overflow: hidden;
  border-radius: 3vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2vh;
  cursor: pointer;
  z-index: 99;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.wishlstBTn:hover {
  background-image: linear-gradient(135deg, #f97794 0%, #854de0 90%);
}

.wishlstBTn p {
  font-size: 2.5vh;
  font-family: "Roboto";
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}

.wishlstBTn:hover p {
  color: rgba(255, 255, 255, 0.9);
}
/*CARD*/
/*CARD*/

/*SEARCH SUGGESTIONS*/
/*SEARCH SUGGESTIONS*/
/*SEARCH SUGGESTIONS*/
/*SEARCH SUGGESTIONS*/
/*SEARCH SUGGESTIONS*/

.SearchResultsHomeContainer {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.srhFilters {
  width: 100%;
  height: auto;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.srhFilters p {
  font-size: 2vh;
  line-height: 4vh;
  padding-left: 2vh;
  font-family: "Roboto";
  font-weight: 100;
  font-style: italic;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: underline rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.srhFilters p:hover {
  color: rgba(255, 255, 255, 0.7);
}

.srhSuggestions {
  width: 100%;
  flex-basis: 1;
  flex-grow: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.srhSuggContainer {
  width: 100%;
  height: 90%;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 1vh;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}

.srchContainerSuggest {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  overflow: hidden;
}

.nameCardContainer {
  width: 16vw;
  height: 22.5vh;
  margin: 1.833vw;
}

p.s_active {
  color: rgba(255, 255, 0, 0.9);
  text-decoration: underline rgba(255, 255, 0, 0.4);
}

/*.SearchResultsHomeContainer > div {
  border: 1px solid white;
}*/

/*.Homepage > div {
  border: 1px solid white;
}*/
/*SEARCH SUGGESTIONS*/
/*SEARCH SUGGESTIONS*/
/*SEARCH SUGGESTIONS*/
/*SEARCH SUGGESTIONS*/

.hide {
  display: none;
}
