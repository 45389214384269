.sideSec {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sideSecContainer {
  width: 90%;
  height: 85%;
  display: flex;
  flex-direction: column;
}

.SideSecTop {
  width: 100%;
  height: 15vh;
  display: flex;
  flex-direction: column;
}

.SideSecMiddle {
  width: 100%;
  flex: 1;
}

.SideSecbottom {
  width: 100%;
  height: 10vh;
}

/*TOP SECTION DESIGN*/
/*TOP SECTION DESIGN*/
/*TOP SECTION DESIGN*/
/*TOP SECTION DESIGN*/
/*TOP SECTION DESIGN*/
/*TOP SECTION DESIGN*/

.sideSecTopTitle {
  width: 100%;
  height: 5vh;
  display: flex;
  flex-direction: row;
}

.SideSecTitle {
  flex: 1;
  height: 100%;
}

.SideSecTitle {
  font-size: 4vh;
  line-height: 5vh;
  padding-left: 1vh;
  font-family: "Roboto";
  font-weight: 900;
  color: rgba(255, 255, 255, 0.9);
}

/*HAMBURGER*/
/*HAMBURGER*/
/*HAMBURGER*/
/*HAMBURGER*/
/*HAMBURGER*/

.SideSecMenu {
  width: 5vh;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidemenuicn {
  width: 3vh;
  height: 3vh;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  overflow: hidden;
  opacity: 0.9;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
}

.sidemenuIcnLines {
  width: 3vh;
  height: 0.5vh;
  background-color: rgba(255, 255, 255, 0.9);
  transition: 0.2s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.sidemenuicn:hover .sidemenuIcnLines:nth-child(1) {
  margin-left: -0.5vh;
}
.sidemenuicn:hover .sidemenuIcnLines:nth-child(3) {
  margin-left: 0.5vh;
}

/*ACCOUNT SECTION*/
/*ACCOUNT SECTION*/
/*ACCOUNT SECTION*/

.sideSecTopAccount {
  width: 100%;
  height: 10vh;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.LogInButtons {
  width: 50%;
  height: 5vh;
  display: flex;
  flex-direction: row;
}

.btns {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btns p {
  width: 80%;
  height: 4vh;
  background-color: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 0.5vh;
  font-size: 1.5vh;
  color: rgba(255, 255, 255, 0.7);
  line-height: 4vh;
  text-align: center;
  font-family: "Roboto";
  font-weight: 100;
  cursor: pointer;
  transition: 0.2s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.btns p:hover {
  background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.5);
}

/*ACCOUNT SECTION*/
/*ACCOUNT SECTION*/

/*USE ACCOUNT*/
/*USE ACCOUNT*/
/*USE ACCOUNT*/

.UserInfo {
  width: 70%;
  margin-left: 1vh;
  height: 7vh;
  display: flex;
  flex-direction: row;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 4vh;
  align-items: center;
}

.userAvtar {
  width: 7vh;
  height: 7vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AvtarImg {
  width: 5vh;
  height: 5vh;
  border-radius: 2.5vh;
  overflow: hidden;
  position: relative;
  cursor: pointer;
}

.userAvtar img {
  width: 5vh;
  height: 5vh;
  border-radius: 2.5vh;
}

.AvtarOverLay {
  position: absolute;
  width: 5.2vh;
  height: 5.2vh;
  margin-top: 0;
  background-color: rgba(0, 0, 0, 0.7);
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}

.AvatarOverLayIcn {
  width: 1.8vh;
  height: 1.8vh;
  background: url("../../images/external.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  opacity: 0.9;
}

.AvtarImg:hover .AvtarOverLay {
  margin-top: -5.5vh;
}

.userInfoOthers {
  flex: 1;
  height: 5vh;
  margin-right: 1vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.uioTop {
  width: 95%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.uioTop p {
  font-size: 2.5vh;
  line-height: 3vh;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto";
  font-weight: 100;
}

.uioDown {
  width: 95%;
  height: 2vh;
  display: flex;
  align-items: center;
}

.uioDown p {
  font-size: 1.1vh;
  font-style: italic;
  font-family: "Roboto";
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  cursor: pointer;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.uioLinks {
  display: flex;
  margin-right: 1vh;
  align-items: center;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.uioLinks:hover p {
  text-decoration: underline rgba(255, 255, 255, 0.5);
}

.externalLinkInc {
  width: 1vh;
  height: 1vh;
  background: url("../../images/external.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  margin-left: 0.3vh;
  transition: 0.3s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.uioLinks:hover .externalLinkInc {
  margin-left: 0.55vh;
}

/*USE ACCOUNT*/
/*USE ACCOUNT*/
/*USE ACCOUNT*/
/*USE ACCOUNT*/

/*MIDDLE SEC*/
/*MIDDLE SEC*/
/*MIDDLE SEC*/

.SideSecMiddle {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.nextpageDiv {
  width: 60%;
  height: 8vh;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-right: 2vh;
  margin-bottom: 2vh;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 4vh;
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.nextpageDiv:hover {
  background-color: rgba(0, 0, 0, 0.4);

  width: 65%;
}

.nextPageIcn {
  width: 8vh;
  height: 8vh;
  border-radius: 4vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nextPageIcnImg {
  width: 5vh;
  height: 5vh;
  background: url("../../images/next.svg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  opacity: 0.7;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.NextPageLabel p {
  font-size: 3vh;
  font-family: "Roboto";
  font-weight: 900;
  color: rgba(255, 255, 255, 0.7);
  padding-right: 2vh;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.nextpageDiv:hover p {
  color: rgba(255, 255, 255, 0.9);
  padding-right: 5vh;
}
.nextpageDiv:hover .nextPageIcnImg {
  opacity: 0.9;
}

/*MIDDLE SEC*/
/*MIDDLE SEC*/
/*MIDDLE SEC*/
/*MIDDLE SEC*/

/*DOWNLOAD BTNS*/
/*DOWNLOAD BTNS*/
/*DOWNLOAD BTNS*/

.SideSecbottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.scbContainer {
  width: 90%;
  height: 9vh;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 2vh;
  overflow: hidden;
}
.scbContainer2 {
  width: 100%;
  height: 18vh;
  display: flex;
  flex-direction: column;
}

.downloadLabel {
  width: 100%;
  height: 9vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.downloadIcn {
  width: 2.5vh;
  height: 2.5vh;
  background: url("../../images/download.svg");
  background-repeat: no-repeat;
  background-position: 100% auto;
  opacity: 0.7;
}

.downloadLabel p {
  font-size: 3vh;
  font-family: "Roboto";
  font-weight: 600;
  line-height: 2.5vh;
  margin-left: 1vh;
  color: rgba(255, 255, 255, 0.7);
}

.downloadBtns {
  width: 100%;
  height: 9vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SideBtns {
  width: 9vw;
  height: 3vw;
  cursor: pointer;
  opacity: 0.7;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.SideBtns:hover {
  opacity: 0.9;
}

.google {
  background: url("../../images/google.svg");
  background-size: 105% 105%;
  background-position: center;
  background-repeat: no-repeat;
}

.apple {
  background: url("../../images/apple.svg");
  background-position: center;
  background-size: 85% 85%;
  background-repeat: no-repeat;
}

/*DOWNLOAD BTNS*/
/*DOWNLOAD BTNS*/

/*.sideSecContainer div {
  border: 1px solid white;
}*/

/*.sideSecContainer {
  border: 1px solid white;
}*/
