.m-footer {
  width: 100%;
  height: 12vh;
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.m-footerItems {
  flex: 1;
  height: 8vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  cursor: pointer;
}

.m-footerItems.selected {
  opacity: 1;
}

.m-footerIcn {
  width: 4vh;
  height: 4vh;
}

.m-footerItems > p {
  margin-top: 0.5vh;
  font-size: 1.8vh;
  color: rgba(255, 255, 255, 1);
  font-family: "Roboto";
  font-weight: 100;
}

.m-footerIcn.allNames {
  background: url("../../images/mob/list.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.m-footerIcn.wish {
  background: url("../../images/mob/wishList.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}

.m-footerIcn.profile {
  background: url("../../images/mob/profile.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
.m-footerIcn.home {
  background: url("../../images/mob/home.svg");
  background-repeat: no-repeat;
  background-size: 100%;
}
