.m-Home {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

/*SEARCH BOX DESIGN*/
/*SEARCH BOX DESIGN*/
/*SEARCH BOX DESIGN*/

.m-SearchBox {
  width: 85%;
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.m-searchBar {
  width: 90%;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-searchBar > input {
  border: none;
  width: 85%;
  height: 5vh;
  background: transparent;
  font-size: 3vh;
  line-height: 5vh;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Roboto";
  font-weight: 600;
}

.m-searchInc {
  width: 5vh;
  height: 5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-searchInc > div {
  width: 3vh;
  height: 3vh;
  background: url("../../images/search.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.8;
}

.m-searchInc.close > div {
  width: 3vh;
  height: 3vh;
  background: url("../../images/close.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.8;
}

.m-WhiteLine {
  width: 100%;
  height: 1vh;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 1vh;
  margin-top: 1vh;
}

.m-sText {
  position: absolute;
  font-size: 3vh;
  line-height: 5vh;
  color: rgba(255, 255, 255, 0.9);
  font-family: "Roboto";
  font-weight: 600;
  top: 4.25vh;
  left: 6vw;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}
.m-sText.active {
  font-size: 1.5vh;
  line-height: 4.25vh;
  top: 0;
  left: 6vw;
}

/*SEARCH BOX DESIGN*/
/*SEARCH BOX DESIGN*/
/*SEARCH BOX DESIGN*/
/*SEARCH BOX DESIGN*/

/*SEARCH RESULTS*/
/*SEARCH RESULTS*/
/*SEARCH RESULTS*/
/*SEARCH RESULTS*/
.SearchResultsHome {
  margin-top: -5vh;
}
.m-searchResults {
  width: 80%;
  height: 65vh;
  display: flex;
  justify-content: center;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  margin-top: -2vh;
}

.m-SearchResultsHomeContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.m-srhFilters {
  width: 100%;
  height: auto;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.m-srhFilters p {
  font-size: 2vh;
  line-height: 3vh;
  padding-left: 2vw;
  font-family: "Roboto";
  font-weight: 100;
  font-style: italic;
  color: rgba(255, 255, 255, 0.9);
  text-decoration: underline rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

p.s_active {
  color: rgba(255, 255, 0, 0.9);
  text-decoration: underline rgba(255, 255, 0, 0.4);
}

.m-srhFilters p:hover {
  color: rgba(255, 255, 255, 0.7);
}

.m-srhSuggestions {
  width: 100%;
  flex-basis: 1;
  flex-grow: 1;
  display: flex;
  align-items: center;
  overflow: hidden;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.m-nameCardContainer {
  width: 90%;
  height: 23vh;
  margin: 5%;
}
/*SEARCH RESULTS*/
/*SEARCH RESULTS*/
/*SEARCH RESULTS*/
/*SEARCH RESULTS*/

/*PART TWO DESIGN*/
/*PART TWO DESIGN*/
/*PART TWO DESIGN*/
/*PART TWO DESIGN*/

.m-hpPartTwo {
  width: 80%;
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

/*GENDER BTN*/
/*GENDER BTN*/
.m-genderBtns {
  width: 70vw;
  height: 5vh;

  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.m-genderBtns.fade {
  opacity: 0.1;
}

.m-genderBtn {
  cursor: pointer;
  width: 25%;
  height: 4.5vh;
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-radius: 1vh;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.m-genderBtn:nth-child(2) {
  margin-left: 5%;
}

.m-genderBtn p {
  text-align: center;
  line-height: 4.5vh;
  font-size: 2vh;
  color: rgba(255, 255, 255, 0.7);
  font-family: "Roboto";
  font-weight: 100;
}

.m-genderBtn:hover {
  background: rgba(255, 255, 255, 0.2);
}
.m-genderBtn:hover p {
  color: rgba(255, 255, 255, 0.9);
}

.m-genderBtn.selected {
  background: rgba(14, 255, 14, 0.1);
  border-bottom: 0.3vh solid rgba(14, 255, 14, 0.9);
}

.m-genderBtn.selected:hover {
  background: rgba(14, 255, 14, 0.2);
  border-bottom: 0.3vh solid rgba(14, 255, 14, 0.9);
}
/*GENDER BTN*/
/*GENDER BTN*/

.m-religion {
  width: 70vw;
  height: 10vh;

  display: flex;
  justify-content: center;
  align-items: center;
}

.m-religionDropDown {
  position: absolute;
  width: 100%;
  height: 18vh;
  margin-top: 1vh;
  background: rgba(255, 255, 255, 0.5);
  border-bottom: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-top: 0.3vh solid rgba(255, 255, 255, 0.9);
  border-radius: 1vh;
  z-index: 9999;
}

.m-rgDropDownContainer {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  margin-top: 1vh;
  margin-bottom: 1vh;
  align-items: center;
}

/*RANDOM BTN*/
/*RANDOM BTN*/
.m-randomBtnBig {
  width: 70vw;
  height: 15vh;

  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.m-randomBtnBig.fade {
  opacity: 0.05;
}

.m-randomBtnBig p {
  font-size: 3vh;
  text-align: center;
  line-height: 8vh;
  width: 55%;
  height: 8vh;
  color: rgba(255, 255, 255, 0.8);
  background-image: linear-gradient(135deg, #f97794 10%, #854de0 100%);
  overflow: hidden;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.75);
  border-radius: 5vh;
  cursor: pointer;
  font-family: "Roboto";
  font-weight: 100;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
}

.m-randomBtnBig p:hover {
  background-image: linear-gradient(135deg, #f97794 0%, #854de0 90%);
  box-shadow: 6px 8px 5px 0px rgba(0, 0, 0, 0.75);
}
/*RANDOM BTN*/

/*PART TWO DESIGN*/
/*PART TWO DESIGN*/
/*PART TWO DESIGN*/
/*PART TWO DESIGN*/

.m-homePageNCcontainer {
  width: 90%;
  height: 22vh;
}
