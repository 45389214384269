.m-profile {
  width: 100%;
  height: 88%;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;

  align-items: center;
}

.m-profileContainer {
  width: 90%;
  height: 80%;
}

.m-logInSec {
  width: 100%;
  height: 60vh;
  border-radius: 2vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
