body {
  overflow: hidden;
}
.body {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  position: relative;
}

/*CONTAINER*/
/*CONTAINER*/
/*CONTAINER*/
/*CONTAINER*/
/*CONTAINER*/

.indexContainer {
  width: 200vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: 1s;
  transition-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
}

.indexContainer.page2 {
  left: -100vw;
}

.icItemOne {
  width: 75vw;
  height: 100%;
}

.icItemTwo {
  width: 25vw;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.icItemThree {
  width: 100vw;
  height: 100%;
}

.scrollBar {
  width: 0.5vw;
  height: 95%;
  position: absolute;
  border-radius: 0.5vw;
  overflow: hidden;
  right: 1%;
  background-color: rgba(0, 0, 0, 0.1);
}

.scrollBarDragable {
  width: 95%;
  min-height: 10%;
  margin-left: 2.5%;
  border-radius: 0.5vw;
  background-color: rgba(0, 0, 0, 0.5);
}
