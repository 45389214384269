.bg {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(135deg, #12c2e9, #c471ed, #f64f59);
  animation-name: bgHueChange;
  animation-timing-function: cubic-bezier(0.165, 0.84, 0.44, 1);
  animation-duration: 180s;
  animation-iteration-count: infinite;
}

@keyframes bgHueChange {
  0% {
    filter: hue-rotate(-30deg);
  }
  25% {
    filter: hue-rotate(0deg);
  }
  50% {
    filter: hue-rotate(-30deg);
  }
  75% {
    filter: hue-rotate(0deg);
  }
  100% {
    filter: hue-rotate(-30deg);
  }
}
